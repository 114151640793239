<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddTagsModel from '@/modules/tags/add-tags-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import SelectWithCheckBox from '@/shared/components/SelectorWithCheckBox.vue';
import { getIdFromUrl } from 'vue-youtube';
import i18n from '../plugins/vue-i18n';

const { fields } = AddTagsModel;
const store = {
  state: {
    options: [],
    optionsDep: [],
    optionsCategories: [],
    optionsGroup: [],
    Video: '',
    questions: [],
    category: 0,
  },
};
export default {
  name: 'AddTag',
  components: {
    SelectWithCheckBox,
    BaseButton,
  },
  props: {
    tagId: {
      type: Number,
      required: true,
      default: 0,
    },
    categoryId: {
      type: Number,
      required: true,
      default: 0,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    editCard: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      model: {},
      dropped: 0,
      OldVideo: [],
      NewVideo: [],
      error: '',
      idYoutube: null,
      files: [],
      file: null,
      filesLibrary: [],
      droppedLibrary: 0,
      OldFile: null,
      errorLibrary: '',
      isPublic: false,
      isPremium: false,
    };
  },
  methods: {
    openAddTag() {
      const addTagWindow = document.querySelector('.add-item');
      addTagWindow.classList.toggle('show');
      if (addTagWindow.classList.value.includes('hide')) {
        addTagWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addTagWindow.classList.toggle('hide');
        }, 300);
      }
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    ListBranches(parentId, parentType) {
      const listDepPath = '/WebListBranches.php';
      const data = new FormData();
      data.append('parent_id', parentId);
      data.append('parent_type', parentType);
      data.append('levels', 2);
      fetch(server + listDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.branches && json.branches.length) {
          store.state.options = json.branches;
          json.branches.forEach((branch) => {
            if (branch.type === 'department') {
              store.state.optionsDep.push({
                value: branch.id,
                text: branch.name,
              });
              branch.branches.forEach((group) => {
                if (group.type === 'group') {
                  store.state.optionsGroup.push({
                    value: group.id,
                    text: group.name,
                  });
                }
              });
            }
            if (branch.type === 'group') {
              store.state.optionsGroup.push({
                value: branch.id,
                text: branch.name,
              });
            }
          });

          if (parentType === '') {
            this.model.departament = store.state.optionsDep;
          }
          this.model.group = store.state.optionsGroup;
        }
      });
    },
    ListCategories() {
      store.state.optionsCategories = [];
      const listCatPath = '/WebListTagCategories.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.categories && json.categories.length) {
            json.categories.forEach((item) => {
              store.state.optionsCategories.push({
                value: parseInt(item.id, 10),
                text: item.name,
              });
            });
          }
        });
    },
    addTagFunc() {
      this.openAddTag();
      let addTagPath = '';
      const data = new FormData();
      if (parseInt(this.tagId, 10) !== 0) {
        addTagPath = '/WebEditTag.php';
        data.append('id', this.tagId);
      } else {
        addTagPath = '/WebAddTag.php';
      }
      data.append('tag', this.model.tag_name ? this.model.tag_name : '');
      data.append('description', this.model.description_help ? this.model.description_help : '');
      data.append('video', this.NewVideo);
      data.append('youtube', this.model.your_url);
      data.append('is_public', this.isPublic);
      data.append('is_premium', this.isPremium);

      data.append('pdf', this.file !== null ? this.file[0] : '');
      if (store.state.category) {
        data.append('category_ids', store.state.category);
      }
      if (this.categoryId) {
        data.append('category_ids', this.categoryId);
      }
      if (this.model.departament) {
        data.append('department_ids', this.model.departament.map((dep) => dep.value));
      }
      if (this.model.group) {
        data.append('group_ids', this.model.group.map((dep) => dep.value));
      }
      fetch(server + addTagPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            if (json.status === 'ok') {
              this.callback();
            }
          }
        });
    },
    getTag(id) {
      const getTagPath = '/WebGetTagDetails.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + getTagPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.tag !== '') {
            let temp = [];
            const temp2 = [];
            let i = 0;
            let j = 0;
            store.state.options.forEach((opt) => {
              if (opt.type === 'department') {
                temp2[j] = {
                  value: opt.id,
                  text: opt.name,
                };
                j += 1;
              }
              if (opt.type === 'group') {
                temp[j] = {
                  value: opt.id,
                  text: opt.name,
                };
                i += 1;
              }
              json.departments.forEach((dep) => {
                if (dep.id === opt.id) {
                  opt.branches.forEach((group) => {
                    if (group.type === 'group') {
                      temp[i] = {
                        value: group.id,
                        text: group.name,
                      };
                      i += 1;
                    }
                  });
                }
              });
            });
            if (store.state.options.length > 0) {
              store.state.optionsGroup = JSON.parse(JSON.stringify(temp));
              store.state.optionsDep = JSON.parse(JSON.stringify(temp2));
            }

            store.state.category = json.categories[0] ? json.categories[0].id : '';
            this.model.description_help = json.description;
            this.isPublic = json.is_public;
            this.isPremium = json.is_premium;

            this.model.your_url = json.youtube !== 'undefined' ? json.youtube : '';
            this.OldVideo = json.video ? [json.video] : [];
            this.file = json.pdf ? json.pdf : null;
            store.state.idYoutube = json.youtube !== null ? getIdFromUrl(json.youtube) : null;

            temp = [];
            json.departments.forEach((dep) => {
              temp.push({
                value: dep.id,
                text: dep.name,
              });
            });
            this.model.departament = [...temp];

            temp = [];
            json.groups.forEach((dep) => {
              temp.push({
                value: dep.id,
                text: dep.name,
              });
            });
            this.model.group = [...temp];
            this.model.tag_name = json.tag;
          }
        });
      const x = new FormData();
      x.append('tag_id', id);
      fetch('https://api.edneos.com/api/WebListQuestions.php', {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: x,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.questions) {
            store.state.questions = json.questions;
          }
        });
    },
    deleteTag() {
      this.openAddTag();
      const deleteUserPath = '/WebRemoveTag.php';
      const data = new FormData();
      data.append('id', this.tagId);

      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            this.callback();
          }
        });
    },
    deleteCard(id) {
      const deleteUserPath = '/WebRemoveQuestion.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            this.getTag(this.tagId);
          }
        });
    },
    dragOver() {
      this.dropped = 2;
    },
    drop(e) {
      let status = true;
      const files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith('video') === false) status = false;
        });
        if (status === true) {
          e.currentTarget.files = e.dataTransfer.files;
          this.previewVideo(e);
        } else {
          this.error = this.$props.fileError ? this.$props.fileError : 'Acest tip de fișier nu este suportat!';
        }
      }
      this.dropped = 0;
    },
    previewVideo(event) {
      this.files = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        [this.NewVideo] = values;
        this.OldVideo = values;
      });
    },
    dragOverLibrary() {
      this.droppedLibrary = 2;
    },
    dropLibrary(e) {
      let status = true;
      const files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith('image') === false) status = false;
        });
        if (status === true) {
          this.filesLibrary.push(...files);
          this.previewImgsLibrary();
        } else {
          this.errorLibrary = 'Unsupported file type';
        }
      }
      this.droppedLibrary = 0;
    },
    previewImgsLibrary(event) {
      if (this.droppedLibrary === 0) this.filesLibrary.push(...event.currentTarget.files);
      this.errorLibrary = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.filesLibrary.length) return;
      for (let i = 0; i < this.filesLibrary.length; i += 1) {
        readers.push(this.readAsDataURL(this.filesLibrary[i]));
      }
      Promise.all(readers).then((values) => {
        this.file = values;
      });
    },
    updateDep(val) {
      this.model.departament = val;
      store.state.optionsGroup = [];
      val.forEach((dep) => {
        this.ListBranches(dep.value, 'department');
      });
    },
    updateGroup(val) {
      this.model.group = val;
    },
  },
  beforeMount() {
    store.state.category = 0;

    this.ListCategories();
    if (this.categoryId !== 0) {
      store.state.category = this.categoryId;
    }
    if (this.tagId !== 0) {
      this.getTag(this.tagId);
    } else {
      this.model.group = [];
      store.state.optionsDep = [];
      store.state.optionsGroup = [];
      store.state.optionsCategories = [];
      this.ListBranches(0, '');
      this.model.title = '';
    }
  },
};
</script>
<template>
  <div class="add-item">
    <div class="add-item-left" v-on:click="openAddTag">
    </div>
    <div class="add-item-window" v-if="this.tagId === 0 ||
     (this.tagId !== 0 && this.model.tag_name !== '')">
      <form class="form" v-on:submit.prevent="addTagFunc(1)"
            id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.tagId">
          <div class="menu" :key="this.model.tag_name">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddTag"/>
            <div class="title">
              {{ this.tagId !== 0 ? this.model.tag_name : $t('TAGS.ADD_TAG') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit">
              <unicon fill="white" height="16" width="16" name="save"/>
              {{ $t('GENERAL.SAVE') }}
            </BaseButton>
          </div>
          <div class="content">
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.BASIC_INFO') }}
                </div>
              </b-col>
            </b-row>
            <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
              <div style="width: -webkit-fill-available;">
                <div class="custom-input">
                  <label>
                    {{ fields.tag_name.label }}</label>

                    <input
                        type="text"
                        :id="fields.tag_name.name"
                        v-model="model[fields.tag_name.name]"
                        :placeholder="fields.tag_name.label"
                        required
                        maxlength="200"
                    />
                </div>
                <div class="custom-input" :key="store.state.category">
                  <label class="caption-2-reg-12 basic-3">
                    {{ fields.category.label }}</label>
                  <select
                    :id="fields.category.name"
                    disabled
                    class="floatingInput"
                    required>
                    <option v-for="(option,i) in store.state.optionsCategories"
                            :key="i + store.state.category"
                            :value="option.value"
                            :selected="parseInt(option.value,10) === categoryId">
                      {{option.text}}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <b-row v-if="store.state.optionsDep.length > 0 &&
                   ((this.tagId === 0 ) || ( this.tagId !== 0))">
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.DEPARTAMENT') }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4" style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col md="6" class="pe-0 image-answer"  v-if="store.state.optionsDep.length > 0 &&
                   ((this.tagId === 0 ) || ( this.tagId !== 0))">
                <label>
                  {{ $t('TAGS.FIELDS.DEPARTAMENT') }}</label>
                <SelectWithCheckBox
                  :options="store.state.optionsDep"
                  :value="this.model.departament"
                  :update-val="updateDep"
                  :key="'dep' + this.model.departament.length"
                />
              </b-col>
              <b-col md="6" v-if="store.state.optionsGroup.length > 0"
                     :key="store.state.optionsGroup.length"
                     class="pe-0 image-answer"
              >
                <label>
                  {{ $t('TAGS.FIELDS.GROUP') }}</label>
                <SelectWithCheckBox
                  :options="store.state.optionsGroup"
                  :value="this.model.group"
                  :key="'gr' + this.model.group.length"
                  :update-val="updateGroup"
                />
              </b-col>
            </b-row>
            <div class="form-separator">
            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.ACCESS_TO_ALL') }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4" style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col md="12" class="pe-0 image-answer">
                <div class="select-with-check-box" style="height: unset">
                  <div>
                    <div class="option-container"
                    v-on:click="() => {
                      if(!isPublic)
                        this.isPublic = true
                    }">
                      <b-form-checkbox
                          required
                          :checked="isPublic"
                          value="true"
                          unchecked-value="false"
                      />
                      <label class=" basic-1 headline-regular-16">
                        {{ $t('GENERAL.ACCESS_FOR_ALL') }}
                        <unicon name="unlock-alt" fill="#BDBDBD" style="height: 16px"
                                height="16" width="16"/>
                      </label>
                    </div>
                  </div>
                  <div>
                    <div class="option-container"
                         v-on:click="() => {
                      if(isPublic)
                        this.isPublic = false
                    }">
                      <b-form-checkbox
                          required
                          :checked="!isPublic"
                          value="true"
                          unchecked-value="false"
                      />
                      <label class=" basic-1 headline-regular-16">
                        {{ $t('GENERAL.ACCESS_FOR_ME') }}
                        <unicon name="lock-alt" fill="#BDBDBD" style="height: 16px"
                                height="16" width="16"/>
                      </label>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="form-separator">
            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.ACCESS_TO_PREMIUM') }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4" style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col md="12" class="pe-0 image-answer">
                <div class="select-with-check-box" style="height: unset">
                  <div>
                    <div class="option-container"
                    v-on:click="() => {
                      if(!isPremium)
                        this.isPremium = true
                        else
                          this.isPremium = false
                    }">
                      <b-form-checkbox
                          required
                          :checked="isPremium"
                          value="true"
                          unchecked-value="false"
                      />
                      <label class=" basic-1 headline-regular-16">
                        {{ $t('GENERAL.ACCESS_FOR_ALL_TO_PREMIUM') }}
                        <unicon name="unlock-alt" fill="#BDBDBD" style="height: 16px"
                                height="16" width="16"/>
                      </label>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="form-separator">
            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('TAGS.FIELDS.VIDEO') }}
                </div>
              </b-col>
            </b-row>
            <b-row style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col sm="12" v-if="OldVideo.length === 0">
                <div class="custom-input">
                  <label>
                    {{ fields.your_url.label }}</label>
                  <unicon name="link" y="-6" style="top: 36px"
                          width="18" height="18" fill="#828282"/>
                  <input type="text"
                         class="form-control floatingInput"
                         :id="fields.your_url.name"
                         v-model="model[fields.your_url.name]"
                         :placeholder="fields.your_url.label"
                         style="padding-left: 40px;margin-bottom: 16px"
                  >
                </div>
              </b-col>
              <b-col sm="12" :key="this.model.your_url"
                     v-if="this.model.your_url === null || this.model.your_url === ''
                     || this.model.your_url === undefined">
                <div
                  class="container-upload"
                  @dragover.prevent="dragOver"
                  @drop.prevent="drop($event)"
                >
                  <div class="drop" v-show="dropped == 2"></div>
                  <!-- Error Message -->
                  <div v-show="error" class="error">
                    {{ error }}
                  </div>

                  <!-- To inform user how to upload image -->
                  <div class="beforeUpload">
                    <input
                      type="file"
                      style="z-index: 1"
                      accept="video/*"
                      ref="uploadInput"
                      @change="previewVideo"
                      multiple
                    />
                    <img class="plus-image" src="/media/fi_upload-cloud.svg" alt=""/>

                    <p class="mainMessage d-inline-flex mb-0">
                      {{ $t("GENERAL.DRAG_AND_DROP_FILES") }}
                    </p>
                  </div>
                </div>
              </b-col>

              <b-col sm="12">
                <iframe
                  class="video-cont"
                  :src="`https://www.youtube.com/embed/${store.state.idYoutube}`"
                  v-if="store.state.idYoutube !== null"
                >
                </iframe>
              </b-col>
              <b-col sm="12" v-for="(video, i) in OldVideo" :key="i">
                <video
                  controls
                  class="video-cont"
                  :src="video"
                  style="max-width: 100%"
                >
                </video>
              </b-col>
            </b-row>

            <b-row>
              <div class="form-separator">
              </div>
            </b-row>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('TAGS.FIELDS.FILE') }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <div>
                <div>
                  <div class="files-container" v-if="this.file !== null">
                    <div class="file-container">
                      <img class="icon" src="/media/pdf.svg" alt=""
                      />
                      <unicon v-on:click="() => {this.file = null}" name="trash-alt" fill="#EB5757"
                              style="margin-right: 6px"/>
                    </div>
                  </div>
                  <div
                    class="container-upload"
                    @dragover.prevent="dragOverLibrary"
                    @drop.prevent="dropLibrary($event)"
                  >
                    <div class="drop" v-show="droppedLibrary == 2"></div>
                    <!-- Error Message -->
                    <div v-show="errorLibrary" class="error">
                      {{ errorLibrary }}
                    </div>

                    <!-- To inform user how to upload image -->
                    <div class="beforeUpload">
                      <input
                        type="file"
                        style="z-index: 1"
                        accept="application/pdf"
                        ref="uploadInput"
                        @change="previewImgsLibrary"
                      />
                      <unicon name="plus" width="24" height="24"
                              style="margin-right: 8px" fill="#1694D0"/>

                      <p class="mainMessage d-inline-flex mb-0">
                        {{ $t("GENERAL.DRAG_AND_DROP_FILES")  }}
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </b-row>
            <b-row>
              <div class="form-separator">
              </div>
            </b-row>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('TAGS.MY_CARDS') }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div class="question-container"
                     v-for="(question, i) in store.state.questions"
                     :key="i">
                  <div class="headline-bold-16 basic-2" style="margin-bottom: 16px">
                    {{ $t('GENERAL.QUESTION') }} {{ i + 1 }}

                    <div class="delete-card" v-on:click="deleteCard(question.id)">
                      <unicon name="trash-alt" fill="#BDBDBD" height="18" width="18"/>
                    </div>
                    <div class="edit-card" v-on:click="editCard(question)">
                      <unicon name="edit-alt" fill="#FFFFFF" height="18" width="18"/>
                    </div>
                  </div>
                  <div style="width: 100%;" v-if="question.image">
                    <img style="max-width: 100%;margin-bottom: 16px;border-radius: 8px"
                         :src="question.image ? question.image : ''"/>
                  </div>
                  <div style="display:inline-flex;width: 100%;margin-bottom: 16px">
                    <div class="time headline-medium-16" style="min-width: 71px!important;">

                      <unicon name="question-circle" fill="#D37B05" height="16" width="16"/>
                    </div>
                    <div class="headline-bold-16 basic-2">
                      {{ question.title }}
                    </div>
                  </div>
                  <div>
                    <div style="display:inline-flex;width: 100%;margin-bottom: 16px"
                         v-for="(answer,j) in question.answers" :key="j">
                      <div :class="'headline-medium-16 index' +
                     (answer.right === true ? '-correct': '')">

                        <unicon v-if="answer.right === true"
                                name="check-circle" fill="#00CA72" height="16" width="16"/>
                        <unicon v-if="answer.right !== true"
                                name="times" fill="#D37B05" height="16" width="16"/>
                      </div>
                      <div class="headline-regular-16 basic-2"> {{answer.answer}}</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col" md="12" v-if="tagId !== 0" style="margin-top: 20px">
                <BaseButton
                  :variant="'danger'"
                  :callback="deleteTag"
                  :callback-params="0"
                  style="width: 100%"
                >

                  <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                  {{ $t('GENERAL.DELETE') }}
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
.delete-card {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  right: 46px;
  top: 8px;
}
.edit-card {
  padding: 6px;
  background: #1694D0;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
}
.delete-card svg,.edit-card svg {
  height: 18px;
  width: 18px;
  margin-top: -2px;
}
.question-container .time {
  padding: 4px 6px;
  height: 27px;
  background: #FFF7D9;
  border-radius: 4px;
  margin-right: 16px;
  color: #D37B05;
  width: 71px;
  text-align: center;
}
.question-container .index {
  padding: 4px;
  height: 27px;
  background: rgba(226, 68, 92, 0.1);
  border-radius: 4px;
  color: #E2445C;
  width: 71px;
  text-align: center;
  margin-right: 16px;
  min-width: 71px!important;
}
.question-container .index-correct {
  padding: 4px;
  height: 27px;
  background: rgba(0, 202, 114, 0.1);
  border-radius: 4px;
  color: #00CA72;
  width: 71px;
  min-width: 71px!important;
  text-align: center;
  margin-right: 16px;
}
.beforeUpload {
  position: relative;
}

.beforeUpload .plus-image{
  height: 32px;
  width: 32px;
  display: inline-flex;
  margin-right: 19px;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload input:hover {
  cursor: pointer;
}

.files-container {
  width: 100%;
}
.file-container {
  padding: 16px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
.file-container .icon{
  margin-right: 8px;
}
.file-container .unicon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.vsa-item__trigger:focus, .vsa-item__trigger:hover {
  background-color: #1694D0;
}
.vsa-item__trigger__icon--is-default:after, .vsa-item__trigger__icon--is-default:before {
  width: 12px;
}
.vsa-item__trigger__icon--is-default {
  width: 12px;
  height: 12px;
}

.vsa-item__trigger__icon--is-default:after {
  transform: rotate(-45deg) translate3d(-1px, -1px, 0);
}
.vsa-item__trigger__icon--is-default:before {
  left: 0;
  transform: rotate(45deg) translate3d(-9px, 9px, 0);
}

.vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:after {
  transform: rotate(-45deg) translate3d(2px,0,0);
}

.vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:before {
  transform: rotate(45deg) translate3d(-2px,0,0);
}

</style>
