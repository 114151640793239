<script>
import BaseButton from '@/shared/components/BaseButton.vue';
import TagCard from '@/shared/cards/TagCard.vue';
import AddCategory from '@/shared/components/AddCategory.vue';
import CategoryCard from '@/shared/cards/CategoryCard.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddCard from '@/shared/components/AddCard.vue';
import AddTag from '@/shared/components/AddTag.vue';
import ShowCardsForTag from '@/shared/components/ShowCardsForTag.vue';

const store = {
  state: {
    categories: [],
    categoryId: 0,
    category: {},
    categorySelected: 0,
    tags: [],
    tagId: 0,
    tagTitleForCard: '',
    cardId: 0,
    withAiForCard: false,
  },
};
export default {
  components: {
    BaseButton,
    CategoryCard,
    AddCategory,
    TagCard,
    AddCard,
    AddTag,
    ShowCardsForTag,
  },
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    ListCategories() {
      store.state.categories = [];
      const listCatPath = '/WebListTagCategories.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.categories && json.categories.length) {
            store.state.categorySelected = 'all';
            store.state.categories = json.categories;
          }
          this.searchTags();
        });
    },
    searchTags() {
      store.state.tags = [];
      const listTagsPath = '/WebListTags.php';
      const data = new FormData();
      data.append('search', '');
      if (store.state.categorySelected !== 0 && store.state.categorySelected !== 'all') {
        data.append('category_id', store.state.categorySelected);
      }
      fetch(server + listTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            }
          }
          if (json.tags && json.tags.length) {
            json.tags.forEach((item) => {
              this.store.state.tags.push({
                id: item.id,
                title: item.tag,
                questions_amount: item.questions_amount,
              });
            });
          } else {
            store.state.tags = [];
          }
        });
    },
    openAddCategory() {
      const addCategoryWindow = document.querySelector('.add-item2');
      addCategoryWindow.classList.toggle('show');
      if (addCategoryWindow.classList.value.includes('hide')) {
        addCategoryWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCategoryWindow.classList.toggle('hide');
        }, 300);
      }
    },
    editCategory(category) {
      setTimeout(() => {
        store.state.category = category;
        store.state.categoryId = category.id ? category.id : 0;
        store.state.depInfo = category !== 0 ? category : {};
      }, 300);
      this.openAddCategory();
    },
    setCategory(category) {
      store.state.categorySelected = category.id;
      this.searchTags();
    },
    openAddTag() {
      const addTagWindow = document.querySelector('.add-item');
      addTagWindow.classList.toggle('show');
      if (addTagWindow.classList.value.includes('hide')) {
        addTagWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addTagWindow.classList.toggle('hide');
        }, 300);
      }
    },
    openShowCards(tag) {
      setTimeout(() => {
        store.state.tagId = tag.id ? tag.id : 0;
      }, 300);
      const addTagWindow = document.querySelector('.show-cards');
      addTagWindow.classList.toggle('show');
      if (addTagWindow.classList.value.includes('hide')) {
        addTagWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addTagWindow.classList.toggle('hide');
        }, 300);
      }
    },
    openAddCart(title) {
      console.log(store.state.tagTitleForCard);
      const addCardWindow = document.querySelector('.view-item');
      store.state.tagTitleForCard = title ?? '';
      addCardWindow.classList.toggle('show');
      if (addCardWindow.classList.value.includes('hide')) {
        addCardWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCardWindow.classList.toggle('hide');
        }, 300);
      }
    },
    editTag(tag) {
      setTimeout(() => {
        store.state.tagId = tag.id ? tag.id : 0;
      }, 300);
      this.openAddTag();
    },
    addCard(title) {
      store.state.tagTitleForCard = null;
      store.state.withAiForCard = false;
      store.state.cardId = 0;
      setTimeout(() => {
        this.openAddCart(title);
      }, 300);
    },
    addCardWithAi(title) {
      store.state.tagTitleForCard = null;
      store.state.withAiForCard = true;
      store.state.cardId = 0;
      setTimeout(() => {
        this.openAddCart(title);
      }, 300);
    },
    editCard(card) {
      store.state.cardId = card.id;
      setTimeout(() => {
        this.openAddCart();
      }, 300);
    },
  },
  beforeMount() {
    this.ListCategories();
  },
};
</script>
<template lang="html">
  <div>
    <div>
      <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: flex;align-items: center">
          {{ $t("TAGS.CATEGORIES") }}
        </div>
        <div class="basic-3 subhead-reg-14" style="margin: 6px 0;display: flex;align-items: center">
          <unicon name="estate" width="16" height="16" fill="#1694D0"></unicon>
          <unicon name="angle-right" width="20" height="20" fill="#828282"/>
          <div class="d-inline default-color">
            {{ $t("TAGS.CATEGORIES") }}
          </div>
          <unicon name="angle-right" width="20" height="20" fill="#828282"/>

          <div class="d-inline">
            {{ store.state.categories.find(item => item.id === store.state.categorySelected) &&
          store.state.categories.find(item => item.id === store.state.categorySelected).name }}
          </div>
        </div>

      </div>
    </div>
    <b-row style="margin: 0 -12.5px!important;">
      <b-col md="3" sm="12">
        <div class="card" style="padding: 16px;height: calc(100vh - 178px)">
          <div v-on:click="editCategory(0)"
               style="width: -webkit-fill-available;margin-bottom: 16px">
            <BaseButton style="width: -webkit-fill-available;">
              <unicon name="plus" width="16" height="16"
                      style="margin-right: 12px;margin-top: -2px" fill="white"/>
              {{ $t('TAGS.ADD_CATEGORY') }}
            </BaseButton>
          </div>
          <div style="height: calc(100vh - 268px); overflow-y: scroll">
            <div>
              <CategoryCard
                :category-info="{id: 'all', name: $t('GENERAL.ALL') }"
                :callback2="setCategory"
                :callback-params2="{id: 'all', name: $t('GENERAL.ALL') }"
                :click-card="false"
                :category-selected="store.state.categorySelected"
              >
              </CategoryCard>
            </div>
            <div v-for="(item,i) in store.state.categories" :key="i">
              <CategoryCard
                :category-info="item"
                :callback="editCategory"
                :callback-params="item"
                :callback2="setCategory"
                :callback-params2="item"
                :click-card="true"
                :category-selected="store.state.categorySelected"
              >
              </CategoryCard>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="9" sm="12">

        <div class="card" style="padding: 0;height: calc(100vh - 178px)">
          <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 0px;
          margin-top: 16px;
          padding: 0 16px">
            <div class="title-0-medium-28 basic-1"
                 style="margin-right: 40px;display: flex;align-items: center">
              {{ $t("TAGS.TAGS") }}
            </div>

            <div v-on:click="editTag(0)"  style="width: -webkit-fill-available;">
              <BaseButton style="float: right" v-if="store.state.categorySelected !== 'all'">
                <unicon name="plus" width="16" height="16"
                        style="margin-right: 12px;margin-top: -2px" fill="white"/>
                {{ $t('TAGS.ADD_TAG') }}
              </BaseButton>
            </div>
          </div>
          <div style="height: calc(100vh - 235px); overflow: auto; padding: 24px 16px 16px 16px; ">
            <b-row>
              <b-col lg="6" sm="12" v-for="(item,i) in store.state.tags" :key="i"
                     style="padding: 0;padding-right: 16px">
                <TagCard
                    :tag-info="item"
                    :index="i"
                    :showCards="openShowCards"
                    :edit="editTag"
                    :edit-params="item"
                    :addCardWithAi="addCardWithAi"
                    :addCard="addCard"
                    :callback-params2="item.title"
                >
                </TagCard>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="add-item2 hide">
      <AddCategory :key="store.state.categoryId" :category="store.state.category">
      </AddCategory>
    </div>
    <div class="add-item hide">
      <AddTag :key="store.state.tagId" :tag-id="store.state.tagId"
              :category-id="parseInt(store.state.categorySelected)" :callback="searchTags"
              :edit-card="editCard"
      >
      </AddTag>
    </div>
    <div class="view-item hide">
      <AddCard :key="store.state.cardId + store.state.tagTitleForCard"
               :tag-title="store.state.tagTitleForCard"
               :card-id="store.state.cardId"
               :with-ai="store.state.withAiForCard"
               :callback="() => {
                 this.searchTags()
                 this.openAddCart();
               }"
               :show-ai-method="true"
      >
      </AddCard>
    </div>

    <div class="show-cards hide">
      <ShowCardsForTag :key="store.state.tagId" :tag-id="store.state.tagId"
      >
      </ShowCardsForTag>
    </div>
  </div>

</template>
